import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CheckoutGuardServiceGuard } from './guard/checkout-guard-service.guard';
const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@penji/checkout/ui').then(
        (mod) => mod.LayoutComponent
      ),
    children: [
      {
        path: '',
        loadComponent: () =>
          // import('@penji/checkout/pages/subscription-one-step').then(
          //   (mod) => mod.SubscriptionOneStepComponent
          // ),
          import('@penji/checkout/pages/subscription-two-step').then(
            (mod) => mod.SubscriptionTwoStepComponent
          ),
        canActivate: [CheckoutGuardServiceGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class CheckoutShellRoutingModule {}
